import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, ApiResponsePageable, DeleteApiResponse, PageControl } from '@model/application';
import { Business } from '@model/business';
import { BusinessIntegration } from '@model/bussines_integration';
import { Invite } from '@model/invite';
import { BusinessPositions } from '@shared/enums/business-position';
import { Utils } from '@shared/utils';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BusinessService {


  constructor(
    private readonly _http: HttpClient
  ) { }

  public getBusiness(pageControl: PageControl, filters?): Observable<ApiResponsePageable<Business>> {
    
    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<Business>>(`${environment.api}/business/search?${paginate}${filterParams}`);
  }

  public postBusiness(business: FormData): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business`, business);
  }

  public getBusinessById(id: string): Observable<ApiResponse<Business>> {
    return this._http.get<ApiResponse<Business>>(`${environment.api}/business/${id}`);
  }

  public openPostBusiness(business: FormData): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/openCreate`, business);
  }

  public getInvites(pageControl: PageControl, filters?): Observable<ApiResponsePageable<Invite>> {
    
    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<Invite>>(`${environment.api}/business/invites?${paginate}${filterParams}`);
  }

  public businessInvite(email): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/invite`, email);
  }

  public acceptInvite(business_id, email): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/invite/accept`, {email, business_id});
  }

  public unlinkInvite(email): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/invite/unlink`, {email});
  }

  public patchBusiness(id: string, business: Business): Observable<ApiResponse<Business>> {
    return this._http.patch<ApiResponse<Business>>(`${environment.api}/business/${id}`, business);
  }

  public deleteBusiness(id: string): Observable<DeleteApiResponse> {
    return this._http.delete<DeleteApiResponse>(`${environment.api}/business/cancel/${id}`);
  }

  public isAdmin(is_admin: number, user_id: string): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/setAdmin`, {is_admin, user_id});
  }

  public isManager(user_id: string): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/setManager`, {user_id});
  }

  public toIntegrate(integration_id: string, params): Observable<ApiResponse<Business>> {
    return this._http.post<ApiResponse<Business>>(`${environment.api}/business/toIntegrate`, {integration_id, params});
  }

  public getBusinessIntegration(): Observable<ApiResponse<BusinessIntegration[]>> {
    return this._http.get<ApiResponse<BusinessIntegration[]>>(`${environment.api}/business/getBusinessIntegration`);
  }

  public cancelIntegration(id): Observable<ApiResponse<BusinessIntegration[]>> {
    return this._http.post<ApiResponse<BusinessIntegration[]>>(`${environment.api}/business/cancelIntegration`, {id});
  }

  public updateBusinessIntegration(params): Observable<ApiResponse<Business>> {
    return this._http.patch<ApiResponse<Business>>(`${environment.api}/business/updateBusinessIntegration`,params);
  }
  
}