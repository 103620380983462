import { PageControl } from "@model/application";

export class Utils {
    
    static toBase64(file: File): Promise<string | ArrayBuffer> {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        })
      }
	static isMobile() {
		return window && window.matchMedia('(max-width: 767px)').matches;
	}

	static mountPageControl(pageControl: PageControl): string {
		let result = '';

		if (!pageControl) {
			return result;
		}

		Object.entries(pageControl).forEach(([key, value]) => {
			result += `${key}=${value}&`;
		});

		return result;
	}

	static filterAutocomplete(items, value, field = 'name', field2 = '') {
        if (typeof value === 'string' && items.length) {
            const filterValue = value
                .toLowerCase()
                .normalize('NFD')
                .replace(/[\u0300-\u036f]/g, '');
            return items.filter(option => {
                return (
                    filterValue === '' ||
                    String(field2 ? option[field][field2] : option[field])
                        .toLowerCase()
                        .normalize('NFD')
                        .replace(/[\u0300-\u036f]/g, '')
                        .includes(filterValue)
                );
            });
        }
    }

    static capitalizeCase(str: string): string {
        let result: string = '';

        str.split(' ').forEach((word, index) => {
            if (index) {
                result += ` ${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`;
                return
            }
            result += `${word[0].toUpperCase()}${word.substring(1).toLowerCase()}`;
        })

        return result;
    }

    static permissionLabel(name: string): string {
        const translations = {
            'view-roles': 'Visualizar Papéis',
            'read-roles': 'Ler Papéis',
            'create-roles': 'Criar Papéis',
            'edit-roles': 'Editar Papéis',
            'delete-roles': 'Deletar Papéis',
            'view-admin': 'Visualizar Administrador',
            'view-permissions': 'Visualizar Permissões',
            'read-permissions': 'Ler Permissões',
            'create-permissions': 'Criar Permissões',
            'edit-permissions': 'Editar Permissões',
            'delete-permissions': 'Deletar Permissões',
            'view-accounts': 'Visualizar Contas',
            'read-accounts': 'Ler Contas',
            'create-accounts': 'Criar Contas',
            'edit-accounts': 'Editar Contas',
            'delete-accounts': 'Deletar Contas',
            'view-business': 'Visualizar Negócios',
            'read-business': 'Ler Negócios',
            'create-business': 'Criar Negócios',
            'edit-business': 'Editar Negócios',
            'delete-business': 'Deletar Negócios',
            'view-business-invite': 'Visualizar Convite de Negócios',
            'read-business-invite': 'Ler Convite de Negócios',
            'create-business-invite': 'Criar Convite de Negócios',
            'edit-business-invite': 'Editar Convite de Negócios',
            'delete-business-invite': 'Deletar Convite de Negócios',
            'view-gateways': 'Visualizar Gateways',
            'read-gateways': 'Ler Gateways',
            'create-gateways': 'Criar Gateways',
            'edit-gateways': 'Editar Gateways',
            'delete-gateways': 'Deletar Gateways',
            'view-categorys': 'Visualizar Categorias',
            'read-categorys': 'Ler Categorias',
            'create-categorys': 'Criar Categorias',
            'edit-categorys': 'Editar Categorias',
            'delete-categorys': 'Deletar Categorias',
            'view-tags': 'Visualizar Tags',
            'read-tags': 'Ler Tags',
            'create-tags': 'Criar Tags',
            'edit-tags': 'Editar Tags',
            'delete-tags': 'Deletar Tags',
            'view-operations': 'Visualizar Operações',
            'read-operations': 'Ler Operações',
            'create-operations': 'Criar Operações',
            'edit-operations': 'Editar Operações',
            'delete-operations': 'Deletar Operações',
            'view-withdrawals': 'Visualizar Saques',
            'read-withdrawals': 'Ler Saques',
            'create-withdrawals': 'Criar Saques',
            'edit-withdrawals': 'Editar Saques',
            'delete-withdrawals': 'Deletar Saques',
            'view-deposits': 'Visualizar Depósitos',
            'read-deposits': 'Ler Depósitos',
            'create-deposits': 'Criar Depósitos',
            'edit-deposits': 'Editar Depósitos',
            'delete-deposits': 'Deletar Depósitos',
            'view-releases': 'Visualizar Lançamentos',
            'read-releases': 'Ler Lançamentos',
            'create-releases': 'Criar Lançamentos',
            'edit-releases': 'Editar Lançamentos',
            'delete-releases': 'Deletar Lançamentos',
            'view-reports': 'Visualizar Relatórios',
            'read-reports': 'Ler Relatórios',
            'create-reports': 'Criar Relatórios',
            'edit-reports': 'Editar Relatórios',
            'delete-reports': 'Deletar Relatórios',
            'view-logs': 'Visualizar Logs',
            'read-logs': 'Ler Logs',
            'create-logs': 'Criar Logs',
            'edit-logs': 'Editar Logs',
            'delete-logs': 'Deletar Logs',
            'view-integrations': 'Visualizar Integrações',
            'read-integrations': 'Ler Integrações',
            'create-integrations': 'Criar Integrações',
            'edit-integrations': 'Editar Integrações',
            'delete-integrations': 'Deletar Integrações',
            'view-users': 'Visualizar Usuários',
            'read-users': 'Ler Usuários',
            'create-users': 'Criar Usuários',
            'edit-users': 'Editar Usuários',
            'delete-users': 'Deletar Usuários',
            'view-settings': 'Visualizar Configurações',
            'read-settings': 'Ler Configurações',
            'create-settings': 'Criar Configurações',
            'edit-settings': 'Editar Configurações',
            'delete-settings': 'Deletar Configurações',
            'view-financial-institution': 'Visualizar Instituições Financeiras',
            'read-financial-institution': 'Ler Instituições Financeiras',
            'view-home': 'Visualizar Página Inicial',
            'view-fast-receipt': 'Visualizar Recibo Rápido'
          };

          return translations[name];
    }
}