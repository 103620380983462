import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { ApiResponse, ApiResponsePageable, DeleteApiResponse, PageControl } from '@model/application';
import { UserInfo, UserValidatorEmail } from '@model/user';
import { Utils } from '@shared/utils';
import { Observable } from 'rxjs';
import { InterceptorSkipHeader } from './auth-interceptor.service';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(
    private readonly _http: HttpClient
  ) { }

  public getUsers(pageControl: PageControl, filters?): Observable<ApiResponsePageable<UserInfo>> {
    
    const paginate = Utils.mountPageControl(pageControl);
    const filterParams = Utils.mountPageControl(filters);

    return this._http.get<ApiResponsePageable<UserInfo>>(`${environment.api}/user/search?${paginate}${filterParams}`);
  }

  public userInfo(): Observable<ApiResponse<UserInfo>> {
    return this._http.get<ApiResponse<UserInfo>>(`${environment.api}/user/info`);
  }
  public infoValidated(): Observable<ApiResponse<UserInfo>> {
    return this._http.get<ApiResponse<UserInfo>>(`${environment.api}/info-validated`, {
      // headers: new HttpHeaders().set(InterceptorSkipHeader, '')
    });
  }                               

  public userUser(id): Observable<ApiResponse<UserInfo>> {
    return this._http.get<ApiResponse<UserInfo>>(`${environment.api}/user/${id}`);
  }

  public postUser(user: UserInfo): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user`, user);
  }
  public resendEmail(): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/resend-email`,{});
  }

  public alterPhone(phone:String): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/change-phone`,{phone});
  }

  public resendPhone(): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/resend-phone`,{});
  }

  public udapteRole(user_id: string, role: string ): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user/update-role`, {user_id, role});
  }
  public recoverPassword(email: string): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user/recover-password`,email);
  }
  public updatePassword(token: string, password: string): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user/update-password`,{token, password});
  }
  public verifyToken(token): Observable<ApiResponse<UserInfo>> {
    return this._http.get<ApiResponse<UserInfo>>(`${environment.api}/user/verify-token-remember-password?token=${token}`);
  }
  public validateEmailExists(email: UserValidatorEmail): Observable<ApiResponse<UserValidatorEmail>> {
    return this._http.post<ApiResponse<UserValidatorEmail>>(`${environment.api}/user/validate_email_exists`, {email});
  }

  public validateEmail(email: string, code: string): Observable<ApiResponse<UserValidatorEmail>> {
    return this._http.post<ApiResponse<UserValidatorEmail>>(`${environment.api}/user/validate_email`, {email, code});
  }

  public validatePhone(email: string, code: string): Observable<ApiResponse<UserValidatorEmail>> {
    return this._http.post<ApiResponse<UserValidatorEmail>>(`${environment.api}/user/validate_phone`, {email, code});
  }


  public openPostUser(user: FormData): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user/openCreate/`, user);
  }

  public patchUser(id: string, user: FormData): Observable<ApiResponse<UserInfo>> {
    return this._http.post<ApiResponse<UserInfo>>(`${environment.api}/user/${id}?_method=PATCH`, user);
  }

  public deleteUser(id: string): Observable<DeleteApiResponse> {
    return this._http.delete<DeleteApiResponse>(`${environment.api}/user/cancel/${id}`);
  }
}
