import {DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {AkitaNgRouterStoreModule} from '@datorama/akita-ng-router-store';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {ToastrModule} from 'ngx-toastr';
import {BrowserstateInterceptor} from './interceptors/browserstate.interceptor';
import {AuthInterceptorService} from '@services/auth-interceptor.service';
import {MatMomentDateModule} from "@angular/material-moment-adapter";
import {APP_BASE_HREF, PlatformLocation, registerLocaleData} from '@angular/common';
import {ColorPickerModule} from 'ngx-color-picker';
import ptBr from '@angular/common/locales/pt';
import {GoogleLoginProvider, SocialAuthServiceConfig} from '@abacritt/angularx-social-login';
import {environment} from '@env/environment';
import {RECAPTCHA_SETTINGS, RecaptchaSettings} from "ng-recaptcha";
import { StepperModule,StepperAllModule } from '@syncfusion/ej2-angular-navigations';
import { MatStepperModule } from '@angular/material/stepper';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';




registerLocaleData(ptBr);

@NgModule({
    declarations: [
        AppComponent
        
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        AkitaNgRouterStoreModule,
        BrowserAnimationsModule,
        RouterModule,
        NgbModule,
        HttpClientModule,
        
        ToastrModule.forRoot({
            positionClass: 'toast-top-right'
        }),
        MatMomentDateModule,
        ColorPickerModule,
        StepperModule,
        StepperAllModule,
        MatStepperModule,
        MatInputModule,
        MatButtonModule,
        
        
    ],
    providers: [
        {provide: 'LOCALSTORAGE', useFactory: getLocalStorage},
        {provide: LOCALE_ID, useValue: 'pt-BR'},
        {provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL'},
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
        {provide: HTTP_INTERCEPTORS, useClass: BrowserstateInterceptor, multi: true},
        {
            provide: APP_BASE_HREF,
            useFactory: (s: PlatformLocation) => s.getBaseHrefFromDOM(),
            deps: [PlatformLocation]
        },
        {
            provide: 'SocialAuthServiceConfig',
            useValue: {
                autoLogin: false, //keeps the user signed in
                providers: [
                    {
                        id: GoogleLoginProvider.PROVIDER_ID,
                        provider: new GoogleLoginProvider(environment.googleClientId, )
                    }
                ]
            } as SocialAuthServiceConfig
        },
        {
            provide: RECAPTCHA_SETTINGS,
            useValue: {siteKey: environment.googleRecaptchaSiteKey} as RecaptchaSettings,
        },
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}

export function getLocalStorage() {
    return typeof window !== 'undefined' ? window.localStorage : null;
}
