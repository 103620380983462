import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, map, Subject, BehaviorSubject} from 'rxjs';
import {environment} from '@env/environment';
import {Auth} from '@model/auth';
import {InterceptorSkipHeader} from './auth-interceptor.service';

@Injectable({
    providedIn: 'root'
})
export class AuthService {

    private _2faConfirmed: BehaviorSubject<boolean> = new BehaviorSubject(false);

    private data_code = new Subject<boolean>();
    code_information$ = this.data_code.asObservable();

    sendInformation(information: boolean) {
        this.data_code.next(information);
    }

    constructor(
        private readonly _http: HttpClient
    ) {
    }

    public set2faConfirmed(data: boolean): void {
        this._2faConfirmed.next(data); 
    }

    public get2faConfirmed(): Observable<boolean> {
        return this._2faConfirmed.asObservable();
    }

    login(auth: Auth): Observable<any> {
        return this._http.post<any>(`${environment.api}/login`, auth, {
            headers: new HttpHeaders()
                .set(InterceptorSkipHeader, '')
                .set('Custom-Header', 'true')
        });
    }

    logout(): Observable<any> {
        return this._http.get<any>(`${environment.api}/logout`);
    }

    isAuthenticated(): Observable<any> {
        return this._http.get<any>(`${environment.api}/validateToken`)
            .pipe(map(res => res.success));
    }

    loginWithGoogle(token: string) {
        return this._http.post(`${environment.api}/login-with-google`, {token});
    }

    registerWithGoogle(token: string) {
        return this._http.post(`${environment.api}/user/registe-with-google`, {token});
    }
}
