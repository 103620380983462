import { NgModule, inject } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule, Routes } from '@angular/router';
import { environment } from '@env/environment';
import { SessionService } from '@store/session/session.service';
import { hasBusinessGuard } from './guards/has-business.guard';
import { hasTwofaGuard } from './guards/has-twofa.guard';
import { isLoggedGuard } from './guards/is-logged.guard';

const routes: Routes = [
  {
    path: '',
    loadChildren: () => import('./views/session/session.module').then(m => m.SessionModule),
    // canMatch: [IsAuthenticatedGuard]
  },
  {
    path: 'painel',
    loadChildren: () => import('./views/private/private.module').then(m => m.PrivateModule),
    canActivate: [isLoggedGuard]
    //canMatch: [hasTwofaGuard]
  },
  {
    path: 'print',
    loadChildren: () => import('./views/print/print.module').then(m => m.PrintModule),
  },
  {
    path: '',
    loadChildren: () => import('./views/public/public.module').then(m => m.PublicModule)    
  },
  {
		path: '**',
		redirectTo: environment.home
	}
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { anchorScrolling: 'enabled'}),
    BrowserModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
