import { Injectable, inject } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router, CanActivate } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { SessionService } from '@store/session/session.service';

@Injectable({
  providedIn: 'root'
})

export class isLoggedGuard implements CanActivate {
    constructor(private router: Router, private sessionService: SessionService) {}
  
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
      return this.sessionService.getUser().pipe(
        map(user => {
          if (user) {
            return true;
          } else {
            this.router.navigate(['/login']);
            return false;
          }
        }),
        catchError(error => {
          console.error('Error fetching user:', error);
          this.router.navigate(['/login']);
          return of(false);
        })
      );
    }
  }
