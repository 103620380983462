export const environment = {
    production: false,
    appName: 'FligPix',
    home: '/painel',
    api: 'https://dev-api.fligpix.com/api',
    // api: 'http://127.0.0.1:8000/api',
    url: 'https://dev.fligpix.com',
    version: require('../../package.json').version,

    passwordRules: {
        minlength: 8,
        minLowercaseCharacterRule: 1,
        minUppercaseCharacterRule: 1,
        minDigitCharacterRule: 1,
        minSpecialCharacterRule: 1
    },

    googleClientId: '913733819215-36qje6q6nqju7c51c1200dmf9ilmjop6.apps.googleusercontent.com',
    googleSecretKey: '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',
    googleRecaptchaSiteKey: '6LdL03kpAAAAAJQ8J7OZcklKBytMefhX-Ai-XmPs',
    googleRecaptchaSecretKey: '6LdL03kpAAAAAK4gdBLyzU053AU1K4NGDD1t-7rT',

    socket: {
        key: "mNKxhCrvOIDjXjfSP3mAVWhm9CBqe3Th",
        wsHost: "socket-dev.fligpix.com",
        wsPort: 443,
        wssHost: "socket-dev.fligpix.com",
        wssPort: 443,
        forceTLS: true,
    }
};
  